import { component } from "haunted";
import "@webcomponents/custom-elements";
import * as BancoEstadoBar from "Components/bancoe-bar/bancoe-bar";
import * as AdminModal from "Components/admin-modal/admin-modal";
import * as DynamicPromoCodes from "Components/admin/dynamic-promo-codes";
import * as DefaultCountries from "Components/admin/route-settings";
import * as InsuranceTab from "Components/admin/insurance-tab-settings";
import * as Searchbox from "Components/searchbox/searchbox";
import * as DcDatepicker from "Components/searchbox/date-selector/dc-datepicker";
import * as SmartTimer from "Components/smart-timer/smart-timer";
import * as MainMenu from "Components/main-menu/main-menu";
import * as PcraLoginInfoModal from "Components/pcra-login-info-modal/peru-compra-login-info-modal";
import * as ChileCompraLoginInfoModal from "Components/chile-compra-login-info-modal/chile-compra-login-info-modal";

export function initCustomElements() {
	customElements.define(
		BancoEstadoBar.name,
		component<HTMLElement & BancoEstadoBar.Props>(BancoEstadoBar.Component, {
			useShadowDOM: BancoEstadoBar.useShadowDOM,
			observedAttributes: [],
		})
	);

	customElements.define(
		AdminModal.name,
		component<HTMLElement & AdminModal.Attributes & AdminModal.Props>(AdminModal.Component, {
			useShadowDOM: AdminModal.useShadowDOM,
			observedAttributes: AdminModal.observedAttributes,
		})
	);

	customElements.define(
		Searchbox.name,
		component<HTMLElement & Searchbox.Attributes & Searchbox.Props>(Searchbox.Component, {
			useShadowDOM: Searchbox.useShadowDOM,
			observedAttributes: Searchbox.observedAttributes,
		})
	);

	customElements.define(
		DcDatepicker.name,
		component<HTMLElement & DcDatepicker.Properties>(DcDatepicker.Component, {
			useShadowDOM: DcDatepicker.useShadowDOM,
			observedAttributes: DcDatepicker.observedAttributes,
		})
	);

	customElements.define(
		DefaultCountries.name,
		component<HTMLElement & DefaultCountries.Props & DefaultCountries.Attributes>(DefaultCountries.Component, {
			useShadowDOM: DefaultCountries.useShadowDOM,
			observedAttributes: DefaultCountries.observedAttributes,
		})
	);

	customElements.define(
		InsuranceTab.name,
		component<HTMLElement & InsuranceTab.Props & InsuranceTab.Attributes>(InsuranceTab.Component, {
			useShadowDOM: InsuranceTab.useShadowDOM,
			observedAttributes: InsuranceTab.observedAttributes,
		})
	);

	customElements.define(
		DynamicPromoCodes.name,
		component<HTMLElement & DynamicPromoCodes.Props & DynamicPromoCodes.Attributes>(DynamicPromoCodes.Component, {
			useShadowDOM: DynamicPromoCodes.useShadowDOM,
			observedAttributes: DynamicPromoCodes.observedAttributes,
		})
	);

	customElements.define(
		SmartTimer.name,
		component<HTMLElement & SmartTimer.Props & SmartTimer.Attributes>(SmartTimer.Component, {
			useShadowDOM: SmartTimer.useShadowDOM,
			observedAttributes: SmartTimer.observedAttributes,
		})
	);

	customElements.define(
		MainMenu.name,
		component<HTMLElement & MainMenu.Props & MainMenu.Attributes>(MainMenu.Component, {
			useShadowDOM: MainMenu.useShadowDOM,
			observedAttributes: MainMenu.observedAttributes,
		})
	);

	customElements.define(
		PcraLoginInfoModal.name,
		component<HTMLElement>(PcraLoginInfoModal.Component, {
			useShadowDOM: PcraLoginInfoModal.useShadowDOM,
			observedAttributes: [],
		})
	);

	customElements.define(
		ChileCompraLoginInfoModal.name,
		component<HTMLElement>(ChileCompraLoginInfoModal.Component, {
			useShadowDOM: ChileCompraLoginInfoModal.useShadowDOM,
			observedAttributes: [],
		})
	);
}
