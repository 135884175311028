import { COOKIES, WebAnonymousRoleCode } from "Services/constants";
import { getCookie } from "Services/cookieHandling";
import { UserInfo } from "Shared/models/UserInfo";
import { decode } from "./PromoCodeHelper";
import { UserInfoCookie } from "Shared/models/UserInfoCookie";
import { toBoolean } from "Services/common";
import { useMemo } from "Shared/haunted/CustomHooks";

export const useUserInfo = (): UserInfo => {
	const retVal = useMemo(() => {
		const userInfo = getCookie(COOKIES.USER_INFO);

		try {
			const decodedUserInfo = userInfo ? (JSON.parse(decode(atob(userInfo))) as UserInfoCookie) : undefined;

			const parsedUserInfo: UserInfo = {
				BancoEstadoCategory: !isNaN(Number(decodedUserInfo?.BancoEstadoCategory))
					? Number(decodedUserInfo?.BancoEstadoCategory || 0) || 0
					: 0,
				BancoEstadoFreeSeats: !isNaN(Number(decodedUserInfo?.BancoEstadoCategory))
					? Number(decodedUserInfo?.BancoEstadoFreeSeats || 0) || 0
					: 0,
				IsBancoEstadoMember: toBoolean(decodedUserInfo?.IsBancoEstadoMember),
				IsCug3Observer: toBoolean(decodedUserInfo?.IsCug3Observer),
				IsCug3Supervisor: toBoolean(decodedUserInfo?.IsCug3Supervisor),
				IsDiscountClubGroupMember: toBoolean(decodedUserInfo?.IsDiscountClubGroupMember),
				IsDiscountClubStandardMember: toBoolean(decodedUserInfo?.IsDiscountClubStandardMember),
				IsPeruCompraAdmin: toBoolean(decodedUserInfo?.IsPeruCompraAdmin),
				IsPeruCompraMember: toBoolean(decodedUserInfo?.IsPeruCompraMember),
				Name: decodedUserInfo?.Name || "",
				Organization: decodedUserInfo?.Organization || "",
				PeruCompraAmount: decodedUserInfo?.PeruCompraAmount || "",
				ProgramCodesWithLevels: decodedUserInfo?.ProgramCodesWithLevels?.split("|") || [],
				ProgramLevels: decodedUserInfo?.ProgramLevels || "",
				RoleCode: decodedUserInfo?.RoleCode || WebAnonymousRoleCode,
			};

			return parsedUserInfo;
		} catch (e) {
			throw new Error("Unable to parse user info cookie.");
		}
	}, []);

	return retVal;
};
